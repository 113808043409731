import React, { useEffect, useState } from "react";
import { useOutletContext } from "react-router";
import Information from "../../ClientInfo/InformationClient";

function ClientInfoWrapper() {
  const { client, company, callback } = useOutletContext() as any;
  return (
    <Information
      client={client}
      company={company}
      callback={callback}
    />
  );
}

export default ClientInfoWrapper;
